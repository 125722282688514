import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
import Helmet from "react-helmet"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import Header from "components/Header/Header.jsx"
import Footer from "components/Footer/Footer.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import HeaderLinks from "components/Header/HeaderLinks.jsx"
import Parallax from "components/Parallax/Parallax.jsx"

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx"

// Sections for this page
import AboutSection from "./Sections/AboutSection.jsx"
import TeamSection from "./Sections/TeamSection.jsx"
import WorkSection from "./Sections/WorkSection.jsx"

const dashboardRoutes = []

class LandingPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props
    return (
      <div id="landing">
        <Helmet title="Utah Polynesian Professionals" />
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="Utah Polynesian Professionals"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 400,
            color: "white",
          }}
          {...rest}
        />
        <Parallax filter image={require("assets/img/omaha-new-zealand.jpg")}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <h1 className={classes.title}>Utah Polynesian Professionals</h1>
                <h4>
                  Utah Polynesian Professionals (UPP) is a formalized network
                  for people seeking career mentorship, professionals seeking to
                  volunteer as mentors, or members that just want to meet and
                  learn from other professionals in the community. Many of our
                  members grew up in villages, and all of our members have
                  benefited from figurative villages – networks of family,
                  friends, and community. The purpose of UPP is to strengthen
                  the village. We do this through mentor groups, meetups, and a
                  strong professional network.
                </h4>
                <br />
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <AboutSection />
            <TeamSection />
            <WorkSection />
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default withStyles(landingPageStyle)(LandingPage)
