import React from "react"
import { createMemoryHistory } from "history"
import { Route, Router, Switch } from "react-router-dom"

import "assets/scss/material-kit-react.scss?v=1.4.0"
import "typeface-roboto"
import "typeface-roboto-slab"
// pages for this product
// import Components from "./Components/Components.jsx"
import LandingPage from "./LandingPage/LandingPage.jsx"
// import ProfilePage from "./ProfilePage/ProfilePage.jsx"P
import SlackPage from "./Slack/SlackPage.jsx"

let hist = createMemoryHistory()

export default () => (
  <Router history={hist}>
    <Switch>
      {/* <Route path="/profile" component={ProfilePage} /> */}
      {/* <Route path="/components" component={Components} /> */}
      <Route path="/slack-page" component={SlackPage} />
      <Route path="/" component={LandingPage} />
    </Switch>
  </Router>
)
